import dom from "../../../../common/ts/core/dom";


function init() {
    showMobileMenu();
    showLoginPopup();
}

const NAVBAR_ACCESS_ACCOUNT = "#navbar-access-account";
const MOBILE_MENU = "#mobile-menu";

function showMobileMenu() {
    dom.select(MOBILE_MENU)?.addEventListener('click', (e) => {
        e.preventDefault()
        fetch(`/jsp/newDesign/menu.jsp?tipoPagina=POR_DEFECTO`, {
            method: 'POST',
            headers: { "Content-Type": "application/json",},
            body: JSON.stringify({}),
        })
            .then(response => response.text())
            .then(async (data) => {
                // @ts-ignore
                document.body.querySelector("#navbar__hamburger__menu").insertAdjacentHTML('afterbegin', data);
                // @ts-ignore
                document.querySelector("#menu")?.style="right:0"
                // @ts-ignore
                document.querySelector(".menu__top__user__email")?.onclick = () => {
                    e.preventDefault();
                    e.stopPropagation();
                    drawLoginModal();
                    hideMenu();
                }
                // @ts-ignore
                document.querySelector("#closeMenuBtn").onclick = () => {
                    e.preventDefault();
                    e.stopPropagation();
                    hideMenu();
                };
                // @ts-ignore
                document.querySelector("#customCountrySelectorId").onchange = (event) => {
                    const optionSelected = event.target.options[event.target.options.selectedIndex];
                    window.location.href = optionSelected.dataset.url;
                };
            })
            .catch(error => console.error(error));
    });
}

function showLoginPopup() {
    dom.select(NAVBAR_ACCESS_ACCOUNT)?.addEventListener('click', () => {
        drawLoginModal();
    });
}

function drawLoginModal(){
    fetch(`/jsp/postYourAd/personalAreaLogin.jsp`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ section: 97 }),
    })
        .then(response => response.text())
        .then(async (data) => {
            await loadGoogleJSLibrary();
            document.body.insertAdjacentHTML('afterbegin', data);
            document.querySelector(".popClose")?.addEventListener('click', () => {
                document.getElementById("popupLogin")?.remove()
            });
        })
        .catch(error => console.error(error));
}

function hideMenu() {
    // @ts-ignore
    document.querySelector("#menu")?.remove();
}
async function loadGoogleJSLibrary() {
    $.getScript("https://apis.google.com/js/api:client.js", function() {
        // @ts-ignore
        gapi.load('auth2', function() {
            // @ts-ignore
            gapi.auth2.init({
                client_id: '63812571663-s203v3fpt91jpste9cm5hh95g472ja39.apps.googleusercontent.com',
                cookiepolicy: 'single_host_origin',
            });
        });
    });
}

export default {
    init,
}