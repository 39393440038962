import header from "../components/header/header";
import dom from "../../../common/ts/core/dom";

dom.init();
header.init();

const propertyTypeLabelElements = document.querySelectorAll('.propertyTypeLabel');
propertyTypeLabelElements.forEach((item) => {
    item.addEventListener('click', () => {
        const property_type_name_field = document.getElementById("propertyTypeName") as HTMLInputElement;
        property_type_name_field.value = item.innerHTML;
    })
});
